import React from 'react';

export const CheckBox = props => {
    return (
      <li className='d-flex flex-row align-items-center' >
       <input key={props.key} onClick={props.handleCheckChieldElement} type="checkbox" checked={props.isChecked} value={props.value} />
       <p style={{color:'black', marginLeft: '5px'}} >{' - '}{props.label}</p>
      </li>
    )
}

export default CheckBox
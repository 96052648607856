import React, { Component } from 'react';
import { Button, Input, Form, Checkbox, TextArea } from 'semantic-ui-react';
import ImageUploader from 'react-images-upload';
import ImgItem from '../components/imgUploadItem';
import CustomCheckBox from '../components/customCheckBox';
import Tour from '../models/tour';
import _ from 'lodash';
import Moment from 'moment';
var DAY = 1000 * 60 * 60 * 24;



export class TourNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pictures: [],
            name: '',
            price: '',
            c_price: '',
            qt: '',
            loading: false,
            edate: '',
            bdate: '',
            desc_text: '',
            guaranteed: { isChecked: false },
            // _diff: Moment(order.bdate).diff(order.edate) / DAY,
            features: [
                { id: '1', value: 'bus', isChecked: false, label: 'Avtobus nəqliyyatı qiymətə daxildir' },
                { id: '2', value: 'trn', isChecked: false, label: 'Dəmiryolu nəqliyyatı qiymətə daxildir' },
                { id: '3', value: 'air', isChecked: false, label: 'Hava nəqliyyatı qiymətə daxildir' },
                { id: '20', value: 'lnc', isChecked: false, label: 'Nahar yeməyi' },
                { id: '23', value: 'spr', isChecked: false, label: 'Şam yeməyi' },
                { id: '4', value: 'sea', isChecked: false, label: 'Gəmi ilə gəzinti' },
                { id: '5', value: 'bb', isChecked: false, label: 'Ancaq səhər yeməyi qiymətə daxildir' },
                { id: '6', value: 'fb', isChecked: false, label: 'Səhər, nahar, şam yeməkləri qiymətə daxildir.' },
                { id: '7', value: 'cas', isChecked: false, label: 'Çay süfrəsi qiymətə daxildir' },
                { id: '8', value: 'hot', isChecked: false, label: 'Oteldə gecələmə qiymətə daxildir' },
                { id: '9', value: 'eks', isChecked: false, label: 'Ekskursiyalar' },
                { id: '10', value: 'fot', isChecked: false, label: 'Foto çəkiliş qiymətə daxildir' },
                { id: '11', value: 'knt', isChecked: false, label: 'Kanat' },
                { id: '12', value: 'ybh', isChecked: false, label: 'Yol boyu əyləncəli və hədiyyəli oyunlar' },
                { id: '13', value: 'bln', isChecked: false, label: 'Balon turu' },
                { id: '14', value: 'atv', isChecked: false, label: '4 təkərli motosikletlə gəzinti' },
                { id: '15', value: 'atc', isChecked: false, label: 'Atçılıq' },
                { id: '16', value: 'ati', isChecked: false, label: 'Atıcılıq' },
                { id: '17', value: 'raf', isChecked: false, label: 'Raftinq' },
                { id: '18', value: 'par', isChecked: false, label: 'Paraplan' },
                { id: '19', value: 'ovc', isChecked: false, label: 'Ovçuluq' },
                { id: '21', value: 'cin', isChecked: false, label: 'Ölkə xarici tur' },
                { id: '22', value: 'cou', isChecked: true, label: 'Ölkə daxili tur' }
            ]
        };
        this.onDrop = this.onDrop.bind(this);
    }


    renderCheckBoxes = () => {
        return _.map(this.state.features, (feature) => {
            return (<CustomCheckBox handleCheckChieldElement={this.handleChecked}  {...feature} />)
        })
    }

    handleChecked = (event) => {
        let features = this.state.features;
        features.forEach(feature => {
            if (feature.value === event.target.value)
                feature.isChecked = event.target.checked
        });
        this.setState({ features })
    }

    onDrop(pictureFiles) {
        this.setState({
            pictures: pictureFiles,
        });
    }

    changeHandler = e => {
        const diff = Moment(this.state.bdate).diff(this.state.edate) / DAY
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    returnFeatures = () => {
        var i = 0;
        var obj = this.state.features;
        var tmpArr = [];

        while (i < obj.length) {
            if (obj[i].isChecked) {
                tmpArr.push(obj[i].value);
            }
            i++;
        }
        return tmpArr.toString();
    }
    prePublish() {
        this.setState({
            loading: true
        }, () => {
            this.publishTour();
        })
    }

    publishTour = () => {
        var features = this.returnFeatures();
        const { name, adult_price, child_price, sng_price, dbl_price, exb_price, qt, edate, bdate, desc_text, guaranteed, ref_policy, pictures } = this.state;
        const trip = { name, adult_price, child_price, sng_price, dbl_price, exb_price, qt, features, edate, bdate, ref_policy, desc_text, guaranteed: guaranteed.isChecked ? '1' : '0' };
        var tur = new Tour(trip);
        tur.publishTour(pictures).then((success) => {

            if (success) {
                alert("Tur ugurla yerleşdirildi")
                window.location.replace('/main_wt/tours')
            } else {
                alert('Tur yaradılarkən xəta baş verdi. Zəhmət olmasa az sonra yenidən cəhd edin.')
                this.setState({
                    loading: false
                })
            }
        });
    }
    renderLoading() {

        return (
            <div>
                <div class="ui active dimmer">
                    <div class="ui text loader">Zəhmət olmasa gözləyin ...</div>
                </div>
            </div>
        )
    }



    render() {
        const {loading,edate,bdate} = this.state;
        const diff = Moment(bdate).diff(edate) / DAY
        return (
            <div>
                {loading ? this.renderLoading() : (
                    <>
                        <div className='d-flex justify-content-between bg-light'>
                            <ul className="list-unstyled" style={{ margin: 10 }} >
                                <li className='h2'>Yeni tur </li>
                                <li className='lead'>Tur əlavə etmək üçün lazımi sahələri doldurun</li>
                            </ul>
                        </div>
                        <hr />
                        <div className='container-fluid d-flex flex-column'>
                            <div className='ui two column stackable grid container'>
                                <div className='column'>
                                    <Form>
                                        <Input fluid name='name' onChange={this.changeHandler} type='text' label='Ad' placeholder='Tur adı' />
                                        <Input fluid onChange={this.changeHandler} name='bdate' label='Başlayır' type='date' />
                                        <Input fluid onChange={this.changeHandler} name='edate' label='Bitir' type='date' />
                                        {/* {!diff > 0 ? <React.Fragment> */}
                                        <Input placeholder='AZN' fluid name='adult_price' min={0} onChange={this.changeHandler} type='number' label='Yetkin' />
                                        <Input placeholder='AZN' fluid name='child_price' min={0} onChange={this.changeHandler} type='number' label='Azyaşlılar' />

                                        {/* </React.Fragment>  :  */}
                                        <React.Fragment>
                                            <Input placeholder='AZN' fluid name='sng_price' min={0} onChange={this.changeHandler} type='number' label='Single' />
                                            <Input placeholder='AZN' fluid name='dbl_price' min={0} onChange={this.changeHandler} type='number' label='Double' />
                                            <Input placeholder='AZN' fluid name='exb_price' min={0} onChange={this.changeHandler} type='number' label='Ex-bed' />
                                        </React.Fragment>
                                        {/* } */}
                                        <Input fluid name='qt' min={0} onChange={this.changeHandler} type='number' label='Stok' placeholder='İlkin olaraq neçə yer nəzərdə tutulub?' />
                                        <TextArea name='desc_text' type='text' label='Tur mətni' placeholder='Tur haqqında...' onChange={this.changeHandler} />
                                        <TextArea name='ref_policy' type='text' label='Qaytarılma şərtləri' placeholder='Qaytarılma şərtləri' onChange={this.changeHandler} />
                                        <Checkbox label='Qarantiyalı' checked={this.state.guaranteed.isChecked} onChange={() => this.setState({ guaranteed: { isChecked: !this.state.guaranteed.isChecked } })} />
                                    </Form>
                                </div>

                                <div className='column'>
                                    <label><b>• Tur xüsusiyyətləri</b></label>
                                    <ul>
                                        {this.renderCheckBoxes()}
                                    </ul>
                                    <label><b>• Tura aid maksimum 5 şəkil seçin</b></label>
                                    <div className='d-flex justify-content-end flex-column' style={{ marginTop: 10 }}>
                                        <p>{this.state.pictures.length} şəkil seçilib</p>
                                        <div className='d-flex justify-content-end' style={{ marginTop: 30 }}>
                                            {
                                                _.map(this.state.pictures, pic => {
                                                    return (
                                                        <ImgItem image={pic}></ImgItem>
                                                    )
                                                })
                                            }
                                        </div>
                                        <ImageUploader
                                            buttonText='Şəkilləri seç'
                                            buttonClassName='ui inverted green button'
                                            fileTypeError='Bu fayl tipi qəbul edilmir.'
                                            fileSizeError='Faylın həcmi çox böyükdür. Maksimum: 10 mb'
                                            onChange={this.onDrop}
                                            label={'Tura aid təsvirləri seçin. Maksimum fayl həcmi: 10 mb. Qəbul edilən fayllar: jpg, png, jpeg, mp4'}
                                            imgExtension={['.jpg', '.png', '.jpeg', '.mp4']}
                                            maxFileSize={5242880} />
                                    </div>
                                    <div className='d-flex justify-content-end' style={{ margin: 30 }}>
                                        <Button onClick={() => window.location.replace('/main_wt/tours')} inverted color="red">Geri</Button>
                                        <Button onClick={() => this.prePublish()} inverted color="green">Turu yerləşdir</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>

                )}

            </div>
        )
    }
}

export default TourNew

import React from 'react';
import PropTypes from 'prop-types'
import Config from '../config/config';
import {Segment,Button, Grid, Image, Modal, Input} from 'semantic-ui-react';

export class SeatItem extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            showInfo: false,
            open: false,
            agency_id:'',
            description: ''
        }
    }

    backgroundColor(status) {
        switch(status){
            case 'av':
                return '#eee'
            case 'full':
                return '#4d4d4d'
            case 'empty':
                return '#ffffff00'
            default: 
                return '#eee'
        }
    }
    
    changeView(){
        this.setState({showInfo: !this.state.showInfo})
    }

    showInfo(){
        const {num,id,gender,user_id,description,status} = this.props.item
        if(status == 'full') {
            return(
                <div>
                    <p className='text-light'>User_id: {user_id}</p>
                    <p className='text-light'>Description: {description}</p>
                    <p className='text-light'>Gender: {gender}</p>
                    <Button content='Bronu sil' icon='delete' onClick={this.removeReservation.bind(this)}/> 
                </div>
            )
        } else {
            return(
                <div>
                    <Button content='Bron' icon='check' onClick={this.modalControl.bind(this)}/> 
                </div>
            )
        }
    }

    modalControl(){
        this.setState({
            open: !this.state.open
        })
    }

    handleChange = (e) => {
        const {name,value} = e.target;
        this.setState({
            [name]:value
        });
    }

    async setReservation() {
        const {bus_name, item} = this.props
        const {num} = item
        const {agency_id,description} = this.state
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/seat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: num,
                description,
                agency_id,
                bus: bus_name,
                gender: 'male'
            }),
        });
        const resJson = await response.json();
        if(resJson.message == 'success'){
            alert('Tamamlandı');
            window.location.reload();
        } else {
            alert('Xəta baş verdi');
        }
    }

    async removeReservation() {
        const {bus_name, item} = this.props
        const {num} = item
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/seat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: num,
                bus: bus_name
            }),
        });
        const resJson = await response.json();
        if(resJson.message == 'success'){
            alert('Rezervasiya silindi.');
            window.location.reload();
        } else {
            alert('Xəta baş verdi');
        }
    }

    render() {
        const {num,status} = this.props.item
        const {showInfo, open} = this.state

        return (
            <Grid.Column>
                <div onClick={()=> this.changeView()} style={{backgroundColor: this.backgroundColor(status)}} className='seat_container' >
                    {parseInt(num) == 0 ? null : num}
                    {
                        showInfo ? this.showInfo() : null
                    }
                </div>
                
                <Modal size='small' open={open} onClose={this.close}>
                    <Modal.Header>Rezervasiya</Modal.Header>
                    <Modal.Content>
                        <div>
                            <Input
                                name='agency_id'
                                value={this.state.agency_id}
                                placeholder='Agentlik id-si'
                                onChange={this.handleChange}
                                fluid
                                style={{width:'100%'}}
                            />
                            <Input
                                name='description'
                                value={this.state.description}
                                placeholder='Description'
                                onChange={this.handleChange}
                                fluid
                                style={{width:'100%'}}
                            />
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.modalControl.bind(this)}>Bağla</Button>
                        <Button onClick={this.setReservation.bind(this)} positive>Rezerv et</Button>
                    </Modal.Actions>
                </Modal>

            </Grid.Column>
        )
    }
}

export default SeatItem;

import React, { useEffect, useState } from 'react'
import { Input, Button, Table, Dropdown, Header } from 'semantic-ui-react';
import UsersTableRow from '../components/UsersTableRow';
import Common from '../config/config';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Users = () => {

    const [infos, setInfos] = useState([])
    const [count, setCount] = useState(20)
    const [offset, setOffset] = useState(0)
    const [key, setKey] = useState('')


    useEffect(()=> {
        fetchUser()
    }, [offset])

    async function fetchUser() {
        const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/users?offset=' + offset + '&count=' + count, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Common,
            }
        });
        const resultJson = await results.json();
        console.log(resultJson,'users')
        setInfos(resultJson.results)
    }

    const changePage = (isNext) => {
        if (isNext) {
            setOffset(offset + 20)
            fetchUser()

        } else if (offset >= 20) {
            setOffset(offset - 20)
            fetchUser()
        }
    }

    const searchforUser = async (e) => {
        setKey(e.target.value)
        setInfos([])
        return async () => {
            //Endpoint searchs for NAME also
            const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/invoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Common,
                },
                body: JSON.stringify({
                    key: key,
                })
            });
            const resultJson = await results.json();
            setInfos(resultJson.results)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div className='header'>
                <Button variant='secondary' icon='user' />
                <h2 className='header-title'>İstifadəçilər</h2>
                <ReactHTMLTableToExcel className="btn btn-success" table="tableToExcel" sheet="Sheet" filename="Istifadeciler" buttonText="Yüklə"/>
            </div>
            <hr></hr>
            <div className='col'>
                <div className='d-flex justify-content-center align-items-center'>
                    <Input icon='search' onChange={(e) => searchforUser(e)} placeholder='İstifadəçi adı və ya id...' />
                </div>
                <div className="orders_table" >
                    <Table id="tableToExcel" color='green' singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>İstifadəçi</Table.HeaderCell>
                                <Table.HeaderCell>İstifadəci ID</Table.HeaderCell>
                                <Table.HeaderCell>Telefon</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
                                {/* <Table.HeaderCell>FCM</Table.HeaderCell> */}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {infos && infos.map(info => <UsersTableRow key={info.id} info={info} />)}
                        </Table.Body>
                    </Table>
                </div>
            </div>
            <div className='row d-flex order-stats'>
                <Button.Group className='fluid'>
                    <Button onClick={() => changePage(false)}>Əvvəlki 20 istifadəçi</Button>
                    <Button>{offset} - {offset + count}</Button>
                    <Button onClick={() => changePage(true)}>Növbəti 20 istifadəçi</Button>
                </Button.Group>
            </div>
        </div>
    )
}

export default Users
import React, { Component } from 'react';
import { Input, Button, Table, Dropdown, Header } from 'semantic-ui-react';
import OrderItem from '../components/orderTableRow';
import Common from '../config/config';

const options = [
    { key: '1', text: 'Hamısı', value: '9' },
    { key: '2', text: 'Ödəniş gözlənilənlər', value: '0' },
    { key: '3', text: 'Təsdiqlənməsi gözlənilənlər', value: '1' },
    { key: '4', text: 'Təsdiqlənənlər', value: '2' },
    { key: '5', text: 'Ödəniş müddəti bitmiş', value: '5' },
    { key: '6', text: 'Başa çatmış', value: '3' },
    { key: '7', text: 'Imtina edilmiş', value: '4' },
]


class Dashboard_WT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            filter: '9',
            count: 20,
            offset: 0
        }
    }

    componentDidMount() {
        this.fetchOrder();
        this.fetchStats();
    }

    async fetchOrder() {
        const { offset, count } = this.state;
        const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/orders?offset=' + offset + '&count=' + count, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Common,
            }
        });
        const resultJson = await results.json();
        this.setState({ orders: resultJson.results });
    }

    changePage = (isNext) => {
        if (isNext) {
            this.setState({ offset: this.state.offset + 20 }, () => this.fetchOrder())
        } else if (this.state.offset >= 20) {
            this.setState({ offset: this.state.offset - 20 }, () => this.fetchOrder())
        }
    }

    async fetchStats() {
        const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/statistics', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + Common,
            }
        });
        const resultJson = await results.json();
        this.setState({ stats: resultJson.results[0] });
    }



    async searchForInvoice(e) {

        this.setState({
            key: e.target.value,
            orders: []
        }, async () => {
            console.log(this.state.key)
            //Endpoint searchs for NAME also
            const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/invoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + Common,
                },
                body: JSON.stringify({
                    key: this.state.key,
                })
            });
            const resultJson = await results.json();
            this.setState({ orders: resultJson.results });
        });
    }

    handleOnChange = (e, data) => {
        this.setState({
            filter: data.value
        })
    }

    renderTable = () => {
        var { filter, orders } = this.state;
        switch (filter) {
            case '9':
                return orders && orders.map(order => {
                    return <OrderItem key={order.id} order={order} />;
                });
            case '0':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 0) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            case '1':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 1) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            case '2':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 2) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            case '3':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 3) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            case '4':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 6 || parseInt(order.status) === 4) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            case '5':
                return orders && orders.map(order => {
                    if (parseInt(order.status) === 5) {
                        return (<OrderItem key={order.id} order={order} />);
                    }
                });
            default:
                return;
        }
    }

    render() {
        const { stats, offset, count } = this.state;

        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className='header'>
                    <Button variant='secondary' icon='cart' />
                    <h2 className='header-title'>Sifarişlər</h2>
                    <Button variant='secondary' icon='cart' />
                </div>
                <hr></hr>
                <div className='row d-flex order-stats'>
                    <Button.Group className='fluid'>

                        <Button>Aktiv sifarişlər: {stats ? stats.active : null}</Button>
                        <Button>Gözləyən sifarişlər: {stats ? stats.waiting : null}</Button>
                        <Button>Ləğv olunan sifarişlər: {stats ? stats.cancelled : null}</Button>
                    </Button.Group>
                </div>
                <hr />
                <div className='col'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <Dropdown placeholder='Filter (Hamısı)' selection options={options}
                            onChange={this.handleOnChange} />
                        <Input icon='search' placeholder='Ad və ya İnvoice...' onChange={(e, key) => this.searchForInvoice(e, key)} />
                    </div>
                    <div className="orders_table" >
                        <Table color='green' singleLine>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Sifarişçi</Table.HeaderCell>
                                    <Table.HeaderCell>Tarix</Table.HeaderCell>
                                    <Table.HeaderCell>Tur adı</Table.HeaderCell>
                                    <Table.HeaderCell>İnvoice</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Total</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderTable()}
                            </Table.Body>
                        </Table>
                    </div>
                    <div className='row d-flex order-stats'>
                        <Button.Group className='fluid'>
                            <Button onClick={() => this.changePage(false)}>Əvvəlki 20 sifariş</Button>
                            <Button>{offset} - {offset + count}</Button>
                            <Button onClick={() => this.changePage(true)}>Növbəti 20 sifariş</Button>
                        </Button.Group>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard_WT;
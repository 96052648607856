import React,{Component} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import TourItem from '../components/agency';
import Common from '../config/config';
import { Button,Header } from 'semantic-ui-react';

class Tours_WT extends Component {

    constructor(props) {
        super(props);
        this.state={
            data:[],
            open:false,
        }
    }

    async getTours() {
        var results = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies',{
            method:'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' + Common,
            }
        })
        var resultsJson = await results.json();
        this.setState({
            data:resultsJson.results
        })
    }

    onClose = () => this.setState({open: false});

    componentDidMount() {
        this.getTours();
    }

    renderList(tours) {
        return _.map(tours,tour => {
            return(
                <TourItem
                key={tour.id}
                tour={tour}/>
            )
        })
    }
    
    render() { 
        return ( 
        <div className='container-fluid'>
            <div className='header'>
                <Button variant='secondary' icon='building'/>
                <h2 className='header-title'>Agentliklər</h2>
                <Button variant='secondary' icon='building'/>
            </div>
            <div className='col section-wt'> 
                <div className='row' >
                    {this.renderList(this.state.data)}
                </div>
            </div>
        </div>
        );
    }
}
 
export default Tours_WT;
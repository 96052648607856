function returnstatus(a) {
    console.log(a)
        switch(a)
        {
            case 0:
                return "Ödəniş gözlənilir";
            case 1:
                return "Ödəniş yoxlanılır";
            case 2:
                return "Ödəniş təsdiqlənib";
            case 22:
                return "Ödənişin 1/2-si təsdiqlənib";
            case 3:
                return "Başa çatmış";
            case 4:
                return "İmtina edilib";
            case 5:
                return "Müddəti bitib";
            case 6:
                return "Ləğv edilib (İstifadəçi tərəfindən)";
            default:
                return null;
        }
}

function returnpaymentstatus(a)
{
        switch(a)
        {
            case 0:
                return "Ödəniş terminalı ilə";
            case 1:
                return "Online";
            case 2:
                return "Nəğd ödəniş";
            default:
                return null;
        }
}

function returnImageType(a)
{
        switch(a)
        {
            case 'image/png':
                return "png";
            case 'image/jpg':
                return "jpg";
            case 'image/jpeg':
                return "jpeg";
            default:
                return 'mp4';
        }
}

module.exports.returnImageType = returnImageType;
module.exports.returnstatus = returnstatus;
module.exports.returnpaymentstatus = returnpaymentstatus;
import React, {Component} from 'react';
import {Button} from 'semantic-ui-react';
import Thread from '../components/thread';
import _ from 'lodash';
import Config from '../config/config';
import {Grid} from 'semantic-ui-react';
import io from 'socket.io-client'; 

export default class Messages extends Component {

    state ={
        threads: [],
        onlyActive: true
    }

    componentDidMount(){
        this.getThreads();
        this.runSocket();
    }

    getThreads() {
        console.log('running');
        return fetch('https://weekendtrips.az:8443/api/mod_wt/threads', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            }
        }).then(response => response.json()).then(responseJson => {
            this.setState({
                threads: responseJson.results
            });
            console.log()
        });
    }

    runSocket() {
        var socket = io('https://weekendtrips.az:8443');
        socket.on('threads',()=>this.getThreads());
    }


    renderThreads = () =>{
        var {threads, onlyActive} = this.state;
        return _.map(threads, thread => {
            if(onlyActive){
                if(thread.state == 1) {
                    return(
                        <Grid.Column key={thread.id}>
                            <Thread thread={thread} ></Thread>
                        </Grid.Column>
                    )
                }
            } else {
                return(
                    <Grid.Column key={thread.id}>
                        <Thread thread={thread} ></Thread>
                    </Grid.Column>
                )
            }
            
        })
    }

    changeMessageList=()=>{
        this.setState({
            onlyActive: !this.state.onlyActive
        })
    }

    render() {
        const {onlyActive} = this.state
        return(
            <div className='container-fluid'>
                <div className='header'>
                    <h2 className='header-title'>Mesajlar</h2>
                    <Button onClick={this.changeMessageList} variant='secondary' icon='chat' content={onlyActive ? "Aktiv olanlar" : "Bütün mesajlar"}/>
                </div>
                <hr></hr>
                <div>
                    {this.renderThreads()}
                </div>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { Button,Modal,Input,List, TextArea} from 'semantic-ui-react';
import Moment from 'moment';
import Message from '../components/message';
import _ from 'lodash';
import io from 'socket.io-client'; 
import Thread from '../models/thread';
import Config from '../config/config';


export class ThreadItem extends Component {

    
    state={
        open:false,
        Thread: new Thread(this.props.thread),
        messages: [],
        message:'',
    }

    componentDidMount(){
        this.getMessages();
        
        const socket = io('https://weekendtrips.az:8443',{
            auth: {
              token: Config
            }
          })

        socket.emit('join-room',({sender_id:'10001', thread_id:this.state.Thread._id}))

        socket.on('message',(data) => this.pushNewMessagesToState(data));
      
        socket.on('disconnect', () => {
          this.setState({ connected: false })
        });
      
        socket.on('reconnect', () => {
          this.setState({ connected: true })
        });
    }

    
    

    componentDidUpdate(){
        var element = document.getElementById('messages');
        if(element){
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    }

    getMessages = (data) => {
        this.state.Thread.getMessages().then(messages =>{
            this.setState({ messages });
        });
    }

    pushNewMessagesToState(data) {
        this.setState({messages: [...this.state.messages,...data]})
        console.log(JSON.stringify(data))
    }


    handleChange = (e) => {
        const {name,value} = e.target;
        this.setState({
            [name]:value
        });
    }

    archieveChat = () => {
        this.state.Thread.setThreadState();
    }


    renderMessages=()=>{
        const {messages} = this.state;
        return _.map(messages,message => {
            //this.scrollToBottom();
            return (<Message key={message.id} message={message} />)
        });
    }

    handleKeyPress(target) {
        if(parseInt(target.charCode)===13) {
            this.sendMessage();
        }
    }

    sendMessage() {
        const {Thread,message} = this.state;
        var socket = io('https://weekendtrips.az:8443');
        var sender_id = 10001
        if(message.length > 0){
           // Thread.sendMessage(message);
           socket.emit('chat-message',({token: Config, sender_id, content: message, thread_id: Thread._id}));
            this.setState({
                message:""
            })
        }
    }

    onClose = () => this.setState({open: false});

    render() {
        const {_created,_state, _last_message} = this.state.Thread;
        const {name,user_id} = this.props.thread;

        return (
            <div id='hey' style={{opacity:_state == 1 ? 1 :0.2}} className='threadHolder' onClick={()=>this.setState({open:true})}>
                <div className='message_'>
                    <p className='sender_name'>{name}</p>
                    <p className='text-muted'>
                        {_last_message && _last_message.length > 100 ? _last_message.substring(0, 100)+ "..." : _last_message}
                    </p>
                </div>
                <div>
                    <p className='text-muted'>{Moment(_created).format('DD.MM.YYYY hh:mm')} tarixində yaradılıb. </p>
                    <p className='text-muted'>{parseInt(_state) === 1 ? 'Mövzu aktivdir.' : 'Mövzu passivdir.'}</p>
                </div>
                
               {
                /*
                 <div className='mButtonsHolder'>
                    <Button inverted color='green' >Yazışmanı göstər</Button>
                </div>
                */
               }

                <Modal  size='small' dimmer='inverted' open={this.state.open} onClose={this.onClose} centered>
                    <Modal.Header style={{backgroundColor:'#21ba45'}} >
                        <p className='lead text-white'>{name} - UID: {user_id}</p>
                    </Modal.Header>
                    <Modal.Content>
                        <List id='messages' inverted className='messagesContainer'>
                            {this.renderMessages()}
                        </List>
                    </Modal.Content>
                    <div className='bg-light d-flex flex-row justify-content-between' >
                    {
                        parseInt(_state) === 1 ?
                        <TextArea
                        name='message'
                        value={this.state.message}
                        className='messageTextArea'
                        placeholder='Mesaj yazın ...'
                        onChange={this.handleChange}
                        fluid
                        /> : null
                    }
                    <Button onClick={this.sendMessage.bind(this)} inverted positive>Göndər</Button>
                    </div>
                    <Modal.Actions>
                        <Button onClick={this.archieveChat} inverted color="red">{parseInt(_state) === 1 ? 'Mövzunu passiv et' : 'Mövzunu aktiv et'}</Button>
                    </Modal.Actions>
                </Modal>

            </div>
        )
    }
}

export default ThreadItem;

import Config from '../config/config';

export default class Thread {
    constructor(thread)
    {
        this._id = thread.id;
        this._subject = thread.subject;
        this._user_id = thread.user_id;
        this._mod_id = thread.mod_id;
        this._created = thread.created;
        this._state = thread.state;
        this._last_message = thread.last_message;
        this._last_sender = thread.last_sender;
    }

    async getMessages() {
        const messages = await fetch('https://weekendtrips.az:8443/api/mod_wt/messages/'+this._id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            }
        });
        const messagesJson = await messages.json();
        return messagesJson.results;
    }

    async sendMessage(content) {
        const messages = await fetch('https://weekendtrips.az:8443/api/mod_wt/message', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                thread_id: this._id,
                content,
                to_user: this._user_id
            }),
        });
        const messagesJson = await messages.json();
        return messagesJson.results;
    }

    async setThreadState() {
        const messages = await fetch('https://weekendtrips.az:8443/api/mod_wt/threadstatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                thread_id: this._id,
                state: parseInt(this._state) === 1 ? 0 : 1
            }),
        });
        const messagesJson = await messages.json();
        if (messagesJson.message === 'success') {
            window.location.reload()
        } else {
            alert(messagesJson.message)
        }
    }


}
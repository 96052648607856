import React, { Component} from 'react'
import Cookies from 'universal-cookie';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            height: 0,
            width: 0,
            opt: '',
            email:'',
            password:'',
            cookies: new Cookies()
        }
        window.addEventListener("resize", this.update);
    }
    
    componentDidMount() {
        this.update();
    }

    login(){
        const { email, password, cookies } = this.state;
        fetch('https://weekendtrips.az:8443/api/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    pass: password,
                    email: email.toLowerCase().trim()
                }),
            })
            .then((response) => response.json()).then(async (responseJson) => {
                const {isLoggedIn, role} = responseJson.user;
                this.setState({
                    isLoading: false,
                });
                if (isLoggedIn && role === 'supmod') {
                    cookies.set('authorization', responseJson.user, {path:'/'});
                    window.location.replace('/main_wt/orders');
                } else {
                    alert(' Giriş etməyə icazəniz yoxdur');
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    update = () => {
        const { cookies } = this.state;
        const user = cookies.get('authorization');
        const {location, innerHeight, innerWidth} = window;

        if(user && user.role.length > 2 ) {
            location.replace('/main_wt');
        }
        this.setState({
            height: innerHeight,
            width: innerWidth
        });
    };

    handleChange = e => {
        const {value, name} = e.target
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className='container-fluid centered'>
                <div className='row absolute2'>
                    <div className='col left-col'>
                        <img className='logo' alt='logo' src={require('../img/logo.png')}/> 
                    </div> 
                    <div className='col right-col'>
                        <h1 className='Headerlogin'>Giriş</h1>
                        <p className='description'>Turizm.Az moderator hesabınızla giriş edin</p>
                        <form>
                            <div className="form-group">
                                <input type="email" name="email" onChange={this.handleChange}
                                    className="form-control input" id="inputMail" aria-describedby="emailHelp"
                                    placeholder="E-mail" />
                            </div>
                            <div className="form-group">
                                <input type="password" onChange={this.handleChange} name="password"
                                    className="form-control input" id="inputPass" placeholder="Şifrə" />
                            </div>
                        </form>
                        <button className="learn-more" onClick={this.login.bind(this)}>
                            <div className="circle">
                                <span className="icon arrow"></span>
                            </div>
                            <p className="button-text">Giriş</p>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


export default Login;
import React from 'react';
import PropTypes from 'prop-types'
import moment  from 'moment';

export class Message extends React.Component {

    static propTypes = {
        message: PropTypes.object.isRequired
    }
    
    renderSelf = () => {
        return(
            <div className='messageHolder selfmessage'>
                <p className='message-text-self'>{this.props.message.content}</p>
                <p className='message_date-self'>{moment.unix(this.props.message.created_at).utcOffset('+0400').format('HH:mm YYYY.MM.DD')}</p>
            </div>
        )
    }

    renderIncoming = () => {
        return(
            <div className='messageHolder incomingmessage'>
                <p className='message-text'>{this.props.message.content}</p>
                <p className='message_date'>{moment.unix(this.props.message.created_at).utcOffset('+0400').format('HH:mm YYYY.MM.DD')}</p>
            </div>
        )
    }

    render() {
        const {sender_id} = this.props.message
        return (
            <div className='d-flex flex-column fluid'>
                {/* parseInt(sender_id) === 100 ? this.renderSelf() : this.renderIncoming()*/}
                {this.renderSelf()}
            </div>
        )
    }
}

export default Message;

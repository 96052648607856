import React, { Component } from 'react'
import {Segment, Button, Modal, Input} from 'semantic-ui-react';
import Moment from 'moment';
import Config from '../config/config';
import PropTypes from 'prop-types';

export class AdComponent extends Component {
    static propTypes = {
        ad: PropTypes.object
    }       

    state = {
        open: false,
        editOpen: false,
    }

    componentDidMount(){
        const {action,title,id,banner_id} = this.props.ad;
        this.setState({action,title,id,banner_id})
    }

    changeHandler = e => {
        const {name,value} = e.target;
        this.setState({
            [name]:value,
        });
    }

    close = () => {
        this.setState({
            open: !this.state.open
        });
    }

    closeEdit = () => {
        this.setState({
            editOpen: !this.state.editOpen
        });
    }

    archiveAd = async ()=> {
        const {id,status} = this.props.ad;
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/ads', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id,
                status: status === 'Confirmed' ? 'Declined' : 'Confirmed'
            }),
        });
        const resJson = await response.json();
        const {success} = resJson;
        if(success === 'true') {
            window.location.reload();
        }
    }


    editAd = async ()=> {
        const {id} = this.props.ad;
        const {action,title,banner_id} = this.state;

        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/ads', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id,
                title,
                action,
                banner_id
            }),
        });
        const resJson = await response.json();
        const {message} = resJson;
        if(message === 'success') {
            window.location.reload();
        }
    }

    render() {
        const {ad} = this.props;
        const {open,editOpen} = this.state;
        const status = ad.status =='Confirmed' ? 'Deaktiv' : 'Aktiv'

        return (
            <Segment key={ad.id} style={{maxHeight:"10rem"}} className='d-flex flex-row row' >
                <img
                alt='thumbnail'
                src={`https://weekendtrips.az/app_data/ads_image/${ad.url}`}
                className='ad-image'/>

                <div className='d-flex flex-column col' >
                    <p className='ad-title'>{ad.title}</p>
                    <p className='text-muted' >{Moment(ad.added_on).format('DD.MM.YYYY hh:mm')} tarixində əlavə edilib.</p>
                    <p className='text-muted'>URL: {ad.action}</p>
                    <div className='ad-item-icons' >
                        <Button 
                            color={ad.status =='Confirmed' ? 'gray' : 'yellow'}
                            onClick={()=>this.setState({open:true})}
                            icon='archive'
                            content={`${status} et`}/>
                        <Button 
                            color="yellow"
                            onClick={()=>this.setState({editOpen:true})}
                            icon='edit'
                            content="Dəyişdir"/>
                    </div>
                </div>

                <Modal basic size='mini' open={open} onClose={this.close}>
                    <Modal.Header>Elanın {status} edilməsi</Modal.Header>
                    <Modal.Content>
                        <p>Elan {status} edilsin ?</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>Xeyr</Button>
                        <Button
                        positive
                        icon='checkmark'
                        labelPosition='right'
                        content='Bəli'
                        onClick={this.archiveAd}
                        />
                    </Modal.Actions>
                </Modal>

                <Modal size='small' open={editOpen} onClose={this.closeEdit}>
                    <Modal.Header>Elanda dəyişiklik et</Modal.Header>
                    <Modal.Content>
                        
                        <Input label='Başlıq' placeholder={ad.title} name='title' onChange={this.changeHandler} fluid />
                        <Input label='Prioritetliyi' placeholder='1-7 arası rəqəm' name='banner_id' onChange={this.changeHandler} fluid />
                        <Input label='Keçid adresi' name='action' onChange={this.changeHandler} placeholder='Boş ola bilər'  fluid />
                        {
                            /*
                            <Input label='Başlanacğıc gün' placeholder='İl-AY-GÜN Məsələn: 2022-08-19' name='from_date' onChange={this.changeHandler} fluid />
                            <Input label='Son gün' placeholder='İl-AY-GÜN Məsələn: 2022-08-19' name='expiration_date' onChange={this.changeHandler} fluid />
                            */
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeEdit} negative>Bağla</Button>
                        <Button
                        color={'green'}
                        icon='checkmark'
                        labelPosition='right'
                        content={'Yerləşdir'}
                        onClick={this.editAd}
                        />
                    </Modal.Actions>
                </Modal>

            </Segment>
        )
    }
}

export default AdComponent

import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import configureStore from './redux/reducers/configureStore'
import {Provider} from 'react-redux'
import './styles/index.css';
import './styles/login.scss';
import './styles/App.css';
//import './styles/fragments.scss';
import './styles/main.scss';
import './styles/wt.scss';
import './styles/wt_components.scss';
//import 'semantic-ui-css/semantic.min.css'
import './styles/main_component.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
require('react-web-vector-icons/fonts');
const store = configureStore();

ReactDOM.render(
        <CookiesProvider>
            <Provider store={store}>
                <App />
            </Provider>
        </CookiesProvider>,
document.getElementById('root'));

serviceWorker.unregister();
import Cookies from 'universal-cookie';

function Auth() {
    const cookies = new Cookies();
    const user = cookies.get('authorization');
    if(!user) {
        return false;
    }
    return true;
}

export default Auth;
import Config from '../config/config';

export default class Agency {
    constructor(agency){
        this._id = agency.id;
        this._name = agency.name;
        this._email = agency.email;
        this._username = agency.username;
        this._tax_id = agency.tax_id;
        this._phone = agency.phone;
        this._city_phone = agency.city_phone;
        this._thumbnail = agency.profile_photo;
        this._verified = agency.verified;
        this._comission_a = agency.comission_a;
        this._comission_b = agency.comission_b;
    }

    async toggleAgencyVerification() { 
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                verified: parseInt(this._verified) == 1 ? 0 : 1,
            }),
        });
        const responseJson = await response.json();
        const {success} = responseJson;
        if(success){
            alert(responseJson.success);
            this._active = !this._active;
            window.location.reload()
        } else {
            alert(responseJson.success);
        }
        return this._active;
    }

    async deleteAgency() { 
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id
            }),
        });
        const responseJson = await response.json();
        const {success} = responseJson;
        if(success){
            window.location.reload()
        } else {
            alert(responseJson.success);
        }
        return this._active;
    }

    async editAgencyInformation(newInformations) { 
        const {newMail, newPhone, newTax_id, newName, newCityPhone, newUsername, newComission_a, newComission_b} = newInformations;

        var email = newMail.length > 0 ? newMail : this._email
        var phone = newPhone.length > 0 ? newPhone : this._phone
        var city_phone = newCityPhone.length > 0 ? newCityPhone : this._city_phone
        var tax_id = newTax_id.length > 0 ? newTax_id : this._tax_id
        var name = newName.length > 0 ? newName : this._name
        var username = newUsername.length > 0 ? newUsername : this._username
        var comission_a = newComission_a > 0 ? newComission_a : this._comission_a
        var comission_b = newComission_b > 0 ? newComission_b : this._comission_b
        

        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies/update', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                name,
                phone,
                city_phone,
                email,
                tax_id,
                username,
                comission_a,
                comission_b
            }),
        });
        const responseJson = await response.json();
        const {isUpdated} = responseJson;
        if(isUpdated){
            alert(responseJson.message);
            window.location.reload()
        } else {
            alert(responseJson.message);
        }
    }

    async editAgencyPassword(password) { 
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies/password', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                password
            }),
        });

        const responseJson = await response.json();
        const {isUpdated} = responseJson;
        if(isUpdated){
            alert(responseJson.isUpdated);
            window.location.reload()
        } else {
            alert(responseJson.isUpdated);
        }
    }


    async updateAgencyLogo(image) { 
        const data = new FormData();
        const {_id} = this;
        
        data.append("file", image);
        data.append('id', _id)
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/agencies/logo', {
            method: 'PATCH',
            headers: {
                'Authorization':  'Bearer ' +Config,
            },
            body: data,
        })
        try {
            const responseJson = await response.json();
            console.log(responseJson.message)
            return responseJson.message;
        }
        catch (error) {
            console.log(error)
        }
       
    }
}
import React, { Component } from 'react';
import Config from '../config/config';
import Ad from '../components/ad';
import common from '../config/common';
import { Button,Modal,Input,CustomCalendar } from 'semantic-ui-react';
import ImageUploader from 'react-images-upload';
 
export class AdsScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            adsLoading: true,
            ads: [],
            open: false,
            pictures: [],
            title: '',
            action: '',
            processing: false,
            banner_id: 0,
            start_date: '',
            end_date: ""
        }
    }

    componentDidMount() {
        this.getAds()
    }
    
    onDrop = (pictureFiles) => {
		this.setState({
            pictures: pictureFiles,
        });
    }

    close = () => {
        this.setState({open:false})
    }

    publishAd = async () => {
        
        if (!this.state.processing) {
            const data = new FormData();
            const { title, action, pictures, from_date, expiration_date, banner_id} = this.state;
            var filename = 'image-' + Date.now() + '.' + common.returnImageType(pictures[0].type);
            this.setState({
                processing: true
            });
            const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/ads', {
                method: 'POST',
                body: this.createFormData(data, {
                    filename,
                    title,
                    action,
                    //expiration_date,
                    //from_date,
                    banner_id
                })
            });
            const responseJson = await response.json();
            const { success } = responseJson;
            if (success === 'true') {
                window.location.reload();
                alert('Success!')
            } else {
                alert('Səhv baş verdi.');
                this.setState({
                    processing: false
                });
            }
        }
    }

    createFormData (data,body) {
        for ( var key in body ) {
            data.append(key, body[key]);
        }
        data.append('file',this.state.pictures[0]);
        return data;
    }

    getAds() {
        fetch('https://weekendtrips.az:8443/api/mod_wt/ads', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + Config
            }
          })
          .then((response) => response.json()).then((responseJson) => {
            this.setState({
              ads: responseJson.results,
              adsLoading: false
            });
          })
          .catch((error) => {
            console.error(error);
          });
    }

    changeHandler = e => {
        const {name,value} = e.target;
        this.setState({
            [name]:value,
        });
    }



    render() {
        const {ads, open, pictures, processing} = this.state;
        return (
            <div className='container-fluid'>
                <div className='header'>
                    <Button variant='secondary' icon='adversal'/>
                    <h2 className='header-title'>Tətbiq daxili elanlar</h2>
                    <Button onClick={()=>this.setState({open:true})} color='green' icon='plus'/>
                </div>
                <hr></hr>
                <div>
                    {
                        ads.map((ad,key) => {
                            return(
                                <Ad ad={ad} key={key} />
                            )
                        })
                    }
                </div>

                <Modal size='small' open={open} onClose={this.close}>
                    <Modal.Header>Yeni elan</Modal.Header>
                    <Modal.Content>
                        
                        <Input label='Başlıq' name='title' onChange={this.changeHandler} fluid />
                        <Input label='Prioritetliyi' placeholder='1-7 arası rəqəm' name='banner_id' onChange={this.changeHandler} fluid />
                        <Input label='Keçid adresi' name='action' onChange={this.changeHandler} placeholder='Boş ola bilər'  fluid />
                        {
                            /*
                            <Input label='Başlanacğıc gün' placeholder='İl-AY-GÜN Məsələn: 2022-08-19' name='from_date' onChange={this.changeHandler} fluid />
                            <Input label='Son gün' placeholder='İl-AY-GÜN Məsələn: 2022-08-19' name='expiration_date' onChange={this.changeHandler} fluid />
                            */
                        }
                        <div className='d-flex justify-content-end flex-column' style={{marginTop:10}}>
                        {
                            pictures.length < 1 ? 
                            <ImageUploader 
                            buttonText='Şəkli seç'
                            buttonClassName='ui inverted green button'
                            fileTypeError='Bu fayl tipi qəbul edilmir.'
                            onChange={this.onDrop}
                            label={'1 şəkil seçin. Maksimum fayl həcmi: 5 mb. Qəbul edilən fayllar: jpg, png, jpeg'}
                            imgExtension={['.jpg', '.png' , '.jpeg' ]} 
                            maxFileSize={5242880}/> : null 
                        }
                        <p>{pictures.length} şəkil seçilib</p>
                        </div>

                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.close} negative>Bağla</Button>
                        <Button
                        color={processing ? 'grey' : 'green'}
                        icon='checkmark'
                        labelPosition='right'
                        content={processing ? 'Yüklənir ...' : 'Yerləşdir'}
                        onClick={this.publishAd}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

export default AdsScreen

import React from 'react';
import Moment from 'moment';
import Agency from '../models/agency';
import _ from 'lodash';
import { Button, Modal,Input, Form,Popup, Icon} from 'semantic-ui-react';
import ImageUploader from 'react-images-upload';
import Bus from './bus';
import PropTypes from 'prop-types';


class TourItem extends React.Component {
    static propTypes = {
        tour: PropTypes.object
    }


    constructor(props) {
        super(props);
        this.state = {
            open:false,
            value:"bus",
            changed:[],
            bus_name:'',
            bus_count:0,
            bus_list:[],
            Agency: new Agency(this.props.tour),
            changed: false,
            newMail: '',
            newName: '',
            newCityPhone: '',
            newPhone: '',
            newUsername: '',
            newPassword: '',
            newTax_id: '',
            newComission_a: '',
            newComission_b: '',
        }
    }

    onClose = (e) => {
        const {name} = e.target;
        this.setState({[name]: false});
    }

    renderHotTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="ticket icon orange"></i>
                S.Y
            </div>
        )
    }

    renderGuaranteedTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="check icon green"></i>
                G
            </div>
        )
    }

    renderArchievedTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="archive icon gray"></i>
                A
            </div>
        )
    }

    renderStatusToggler(active){
        return(
            <div className='blockButton' onClick={(event)=>{
                    this.toggleAgencyVerification();
                    event.stopPropagation()}
                }>
                    
                <Popup position='left center' content='Agentliyi aktiv və ya passiv et.' trigger={
                    <Button icon={active ? 'check' : 'archive'} color={active ? 'green' : 'orange'}  />
                } />
                 
            </div>
        )
    } 

    renderEditToggler(){
        return(
            <div className='blockButton' onClick={(event)=>{
                    event.stopPropagation()}
                }>

                <Popup position='left center' content='Agentlik məlumatlarına düzəliş et.' trigger={
                    <Button icon='edit' color='green' onClick={()=>this.setState({open:true})}  />
                } />
                 
            </div>
        )
    } 

    renderRemoveToggler(){
        return(
            <div className='blockButton' onClick={(event)=>{
                    event.stopPropagation()}
                }>

                <Popup position='left center' content='Agentliyi sil' trigger={
                    <Button icon='remove' color='red' onClick={()=>this.deleteAgency()}  />
                } />
                 
            </div>
        )
    } 

    deleteAgency(){
        const response = window.confirm("Agentliyi silmək istədiyinizdən əminsiniz?");

        if(response) {
            this.state.Agency.deleteAgency()
        }
    }

    toggleAgencyVerification() {
        this.state.Agency.toggleAgencyVerification().then(active =>
            this.setState({ active })
        )        
    }

    handleChange = (e) => {
        const {name,value} = e.target;

        this.setState({
            [name]: value,
            changed: true
        });
    }

    updateAgencyInfo(){
        const {newMail, newPhone, newTax_id,newUsername, newCityPhone, newName, Agency, changed, newComission_a,newComission_b} = this.state;
        const newInformations = {
            newMail,
            newPhone,
            newTax_id,
            newName,
            newCityPhone,
            newUsername,
            newComission_a,
            newComission_b
        }
        if(changed){
            Agency.editAgencyInformation(newInformations);
        }
    }

    updateAgencyPassword(){
        const {newPassword, Agency} = this.state;
        if(newPassword.length > 8){
            Agency.editAgencyPassword(newPassword);
        } else {
            alert('Şifrə minimal 8 simvoldan ibarət olmalıdır.')
        }
    }
    
    render() {
        const {Agency, open} = this.state;
        const {newName, newMail, newPhone, newUsername, newCityPhone, newTax_id, newPassword, newComission_a, newComission_b} = this.state
        const {
            _name,
            _verified,
            _thumbnail,
            _email,
            _username,
            _city_phone,
            _phone,
            _tax_id,
            _comission_a,
            _comission_b
        } = Agency;
        return (
            <div className='itemContainer'>
                <div style={{display:"flex",flexDirection:'column',width:'7%'}}>

                    { _verified ? null : this.renderArchievedTag()}       
                
                </div>     
                <img alt="thumb" src={'https://weekendtrips.az/app_data/agency_avatar/'+_thumbnail}/> 
                <div className='col cred_container'>
                    <p className='lead text-dark'>{_name}</p>
                    <p className='shorttexts'>Şəhər telefonu: {_city_phone}</p>
                    <p className='shorttexts'>Mobil nömrə: {_phone}</p>
                    <p className='shorttexts'>Mail: {_email}</p>
                    <p className='shorttexts'>VÖEN: {_tax_id}</p>
                    <p className='shorttexts'>{_verified ? 'Partnyorluq təsdiqlənib, satış səlahiyyəti aktivdir.' : 'Partnyorluq təsdiqlənməyib, satış səlahiyyəti yoxdur.'}</p>
                </div>
                <div className='optionsBlock'>
                    {this.renderStatusToggler(_verified)}
                    {this.renderEditToggler()}
                    {this.renderRemoveToggler()}
                </div>

                <Modal name='open' size='small' dimmer='inverted' open={open} onClose={()=>this.setState({open:false})} centered>
                    <Modal.Header style={{backgroundColor:'#21ba45'}} ><p className='lead text-white'>{_name}  -  Məlumatların dəyişdirilməsi</p></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Form className='d-flex flex-column'>
                                
                                <div className='d-flex flex-column'>
                                    <Form.Field  name='newName' type='text' control={Input} value={newName == '' ? _name : newName} label='Ad' onChange={this.handleChange}/>
                                    <div className='d-flex flex-row'>
                                        <Form.Field  name='newUsername' type='text' control={Input} value={newUsername == '' ? _username : newUsername} label='Username' onChange={this.handleChange}/>
                                        <Form.Field  name='newMail' type='text' control={Input} value={newMail == '' ? _email : newMail} label='Mail' onChange={this.handleChange}/>
                                        <Form.Field  name='newTax_id' type='number' control={Input} value={newTax_id == '' ? _tax_id : newTax_id} label='VOEN' onChange={this.handleChange}/>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <Form.Field name='newPhone' type='text' control={Input} value={newPhone == '' ? _phone : newPhone} label='Mobil telefon' onChange={this.handleChange}/>
                                        <Form.Field name='newCityPhone' type='text' control={Input} value={newCityPhone == '' ? _city_phone : newCityPhone} label='Şəhər telefonu' onChange={this.handleChange}/>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <Form.Field name='newComission_b' type='text' control={Input} value={newComission_b == '' ? _comission_b : newComission_b} label='Komissiya - B' onChange={this.handleChange}/>
                                        <Form.Field name='newComission_a' type='text' control={Input} value={newComission_a == '' ? _comission_a : newComission_a} label='Komissiya - A' onChange={this.handleChange}/>
                                    </div>
                                </div>
                                <Button positive icon='check' content='Məlumatları yenilə' onClick={()=> this.updateAgencyInfo()} />

                                <div className='d-flex flex-row p-3'>
                                    <Form.Field width={5} name='newPassword' type='password' control={Input} value={newPassword} label='Yeni Şifrə' onChange={this.handleChange}/>
                                </div>
                                <Button positive size='small' icon='lock' content='Şifrəni yenilə' onClick={()=> this.updateAgencyPassword()} />
                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>this.setState({open:false})}>İmtina</Button>
                    </Modal.Actions>
                </Modal>                
            </div>
        )
    }
}



export default TourItem;
import React,{Component} from 'react';
import {Input,Button,Table,Dropdown,Header} from 'semantic-ui-react';
import AccItem from '../components/accountingTableRow';
import Common from '../config/config';

class AccountingsScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountings:[],
        }
    }

    componentDidMount() {
        this.fetchAccountings();
    }

    async fetchAccountings() {
        const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/accountings',{
            method:'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Common,
            }
        });
        const resultJson = await results.json();
        this.setState({accountings:resultJson.results});
    }

    
    renderTable() {
        const {accountings} = this.state;
        return accountings && accountings.map(item =>{
            return (<AccItem key={item.id} item={item} />);
        });
    }


    render() { 
        return ( 
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <div className='header'>
                <Button variant='secondary' icon='money'/>
                <h2 className='header-title'>Hesabatlar</h2>
                <Button variant='secondary' icon='money'/>
            </div>
            <hr/>
            <div className='col'>
                <div className="row" >
                    <Table color='green' singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Agentlik</Table.HeaderCell>
                            <Table.HeaderCell>Gəlir</Table.HeaderCell>
                            <Table.HeaderCell>Komissiya</Table.HeaderCell>
                            <Table.HeaderCell>Tarix</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                            {this.renderTable()}
                    </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
        );
    }
}
 
export default AccountingsScreen;
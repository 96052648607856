import React, { Component } from 'react';
import {Button,Table,Modal} from 'semantic-ui-react';
import Common from '../config/common';
import Moment from 'moment';
import Order from '../models/order';



export class OrderTableRow extends Component {

    state = {
        open:false,
        Order: new Order(this.props.order)
    }

    openModal = () =>  this.setState({ open: true }); 
    closeModal = () => this.setState({ open: false });

    renderPayment () {
        const {_payment,_status} = this.state.Order;
        if (_status > 0 && _payment)
        {
            return _payment.map((payment)=>{
                return(
                    <div className='d-flex flex-column paymentSection'>
                        <p className='text-light'>Ödəniş {payment.id}</p>
                        <p className='text-light'>Miqdar:  {payment.amount} azn</p>
                        <p className='text-light'>Növ:  {Common.returnpaymentstatus(payment.type)}</p>
                        <p className='text-light'>Zaman:  {Moment(payment.date).format('DD.MM.YYYY - hh:mm')}</p>
                        <p className='text-light'>Ödəniş nömrəsi (online):  {payment.pay_orderid}</p>
                        <p className='text-light'>Sessiya nömrəsi (online):  { payment.session_id}</p>
                        {
                            parseInt(payment.type) === 0 ? 
                            <a className='text-light' href={'http://weekendtrips.az/app_data/term_img/'+payment.url} target="_blank">ÖDƏNİŞİN TƏSVİRİ</a> : null
                        }
                    </div>
                 )
            })
        }
    }

    render() {
        const {_id, _name,_trip,_invoice,_total,_date,_addons,_user_id,_phone,_status, _agency_name, _agency_id, _beginning_date, _end_date} = this.state.Order;
        const addons = JSON.parse(_addons);
        // const diff = Moment(bdate).diff(edate) / DAY
        return (
            <Table.Row style={{backgroundColor: parseInt(_id) % 2 ? '#F8F9F9' : '#fff'}}>
                <Table.Cell>{_name}</Table.Cell>
                <Table.Cell>{Moment(_date).format('DD.MM.YYYY - HH:mm')}</Table.Cell>
                <Table.Cell>
                    {_trip}
                    <br></br>
                    {_beginning_date.replaceAll('-','.') + " - " + _end_date.replaceAll('-','.')}
                </Table.Cell>
                <Table.Cell>{_invoice}</Table.Cell>
                <Table.Cell>{Common.returnstatus(_status)}</Table.Cell>
                <Table.Cell>{_total} AZN</Table.Cell>
                <Table.Cell>
                    <Button color='green' onClick={this.openModal} >Detallar</Button>
                    {_agency_id == '10000' ? <Button size='tiny' color='orange'>Turizm.Az</Button> : null}
                </Table.Cell>

                <Modal size='small' dimmer='inverted' open={this.state.open} onClose={this.closeModal} centered>
                    <Modal.Header>{_invoice}</Modal.Header>
                    <Modal.Content>
                        <div className='d-flex flex-column' >
                            <p className='h6'>Sifarişçi</p>
                            <p className='lead'>{_name} - Id: {_user_id}, Telefon: {_phone}</p>
                            <p></p>
                            <p className='h6'>Satıcı</p>
                            <p className='lead'>{_agency_name} (id: {_agency_id})</p>
                            <p></p>
                            <p className='h6'>Tur</p>
                            <p className='lead'>{_trip}</p>                                
                            <p></p>
                            <p className='h6'>Zaman</p>
                            <p className='lead'>{Moment(_date).format('DD.MM.YYYY - HH:mm')}</p>
                            <p></p>
                            <p className='h6'>Turistlər: </p>
                            <p className='lead'>{addons.adult} yetkin  |  {addons.child} azyaşlı  |  {addons.infant} körpə</p>
                            <p className='h6'>Nömrələr: </p>
                            <p className='lead'>{addons.dbl_rooms} DoubleRoom  |  {addons.sng_rooms} SingleRoom  |  {addons.extraBeds} ExtraBed  |  {addons.infantBeds} körpə yatağı</p>
                            <p></p>
                            <p className='h6'>Total</p>
                            <p className='lead'>{_total} AZN</p>
                            <p></p>
                            <p className='h6'>Status</p>
                            <p className='lead'>{Common.returnstatus(_status)}</p>
                            <p></p>
                            {this.renderPayment()}
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.closeModal}>Bağla</Button>
                    
                        {/* <Button onClick={()=>this.state.Order.setOrderStatus('22')} positive icon='checkmark' labelPosition='right' content='1/2 hissəsini təsdiqlə' /> */}
                        
                        {parseInt(_status) < 3 ?
                            <Button onClick={()=>this.state.Order.cancelOrder()} negative icon='remove' labelPosition='right' content='Ləğv et' /> :
                            <Button onClick={()=>this.state.Order.recoverOrder()} positive icon='check' labelPosition='right' content='Bərpa et' />
                        }
                        {parseInt(_status) == 1 ? <Button onClick={()=>this.state.Order.verifyPayment()} positive icon='checkmark' labelPosition='right' content='Təsdiqlə' /> : null}
                    </Modal.Actions>
                </Modal>

            </Table.Row>
        )
    }
}

export default OrderTableRow

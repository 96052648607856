import React, { Component } from 'react';
import Mainwt from './screens/main_wt';
import TourNew from './screens/tourNew';
import Login from './screens/login';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Auth from './config/auth';

class App extends Component {
    render() {
        const App = () => (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={Login} />

                    <Route exact path='/main_wt' render={(props) => (
                        Auth()
                            ? <Mainwt />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/orders' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={1} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/users' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={2} />
                            : <Redirect to='/' />
                    )} />

                    <Route exact path='/main_wt/tours' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={3} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/messages' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={4} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/agencies' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={5} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/accountings' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={7} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/ads' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={6} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/bus/id/:bus_id' render={(props) => (
                        Auth()
                            ? <Mainwt activeTab={7} />
                            : <Redirect to='/' />
                    )} />

                    <Route path='/main_wt/tours/new' render={(props) => (
                        Auth()
                            ? <TourNew />
                            : <Redirect to='/' />
                    )} />

                </Switch>
            </BrowserRouter>
        )
        return (
            <App></App>
        );
    }
}

export default App;
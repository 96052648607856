import React, { Component } from 'react';
import Config from '../config/config';
import SeatItem from '../components/seatItem';
import {Segment,Button, Grid, Image, Modal} from 'semantic-ui-react';
import PropTypes from 'prop-types'


export class Bus extends Component {

    static propTypes = {
        bus: PropTypes.object.isRequired
    }

    state = {
        full:0,
        available:0,
        results: [],
        resultsLoading: true
    }

    componentDidMount(){
        if(this.props.bus.name) {
            this.getBuses();
            this.getSeats(this.props.bus.name);
        }
    }
    
    getSeats(busID) {
        var url = 'https://weekendtrips.az:8443/api/mod_wt/seats/'+busID;
        console.log(url)
        fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + Config
            }
          })
          .then((response) => response.json()).then((responseJson) => {
            console.log(JSON.stringify(responseJson))
            this.setState({
              results: responseJson.results,
              resultsLoading: false
            });
          })
          .catch((error) => {
            console.error(error);
          });
    }

    async getBuses() {
        console.log(this.props.bus.name)
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/getbusinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                busname: this.props.bus.name,
            }),
        });
        const resJson = await response.json();
        const {full,available} = resJson.results[0];
        this.setState({
            full,available
        });
    }

    async deleteBus() {
        const {id} = this.props.bus;
        console.log(this.props.bus.id)
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/bus', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                bus_id: id,
            }),
        });
        const resJson = await response.json();
        const {success} = resJson;
        if(success == "true"){
            alert("Tamamlandı!")
            window.location.reload();
        }
    }

    modalControl(){
        this.setState({
            open: !this.state.open
        })
    }

    AskForDeleteBus(){
        let willDelete = window.confirm("Avtobus silinsin? Bu, geri qaytarılması mümkün olmayan bir prosesdir.");
        if(willDelete){
            this.deleteBus()
        } else {
            console.log("Cancelled!")
        }
    }

    render() {
        const {results, open, pictures, processing, resultsLoading, bus} = this.state;
        return (
            <Segment className='d-flex flex-column' >
                <label className='text-muted'>{this.props.bus.scheme_name}</label>
                <label className='lead'>Avtobus kodu: {this.props.bus.name}</label>
                <p className='text-muted'>Boş yerlər: {this.state.available}</p>
                <p className='text-muted'>Dolu yerlər: {this.state.full}</p>
                <div className='d-flex flex-row'>
                    <Button icon='bus' content='Bron' onClick={this.modalControl.bind(this)} />
                    <Button icon='delete' content='Sil' onClick={this.AskForDeleteBus.bind(this)} />
                </div>

                <Modal size='small' open={open} onClose={this.close}>
                    <Modal.Header>"{this.props.bus.name}" kodlu avtobus üçün yerlərin statusu</Modal.Header>
                    <Modal.Content>
                        <div>
                        <Grid columns={this.props.bus.row_count}>
                            <Grid.Row>
                                {
                                    !resultsLoading ? results.map((item,key) => {
                                        return(
                                            <SeatItem bus_name={this.props.bus.name} item={item} />
                                        )
                                    }) : null 
                                }
                            </Grid.Row>
                        </Grid>
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.modalControl.bind(this)}>Bağla</Button>
                    </Modal.Actions>
                </Modal>

            </Segment>
        )
    }
}

export default Bus

import React, { Component } from 'react';
import Menuitem from '../components/menuitem';
import Orders from './orders';
import Users from './users';
import Tours from './tours';
import Agencies from './agencies';
import Messages from './threads';
import Ads from './ads';
import Accountings from './accountings';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';

class Main_wt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 1,
            cookies: new Cookies()
        }
    }

    static propTypes = {
        activeTab: PropTypes.number
    }

    componentDidMount() {
        const { activeTab } = this.props;
        this.setState({
            activeTab: activeTab || 1
        });
    }

    logout() {
        this.state.cookies.remove("authorization", { path: "/" })
        window.location.reload()
    }

    showscreens() {
        const { activeTab } = this.state;
        switch (activeTab) {
            case 0:
                //<Dash></Dash>
                return (null)
            case 1:
                return (<Orders />)
            case 2:
                return (<Users />)
            case 3:
                return (<Tours />)
            case 4:
                return (<Messages />)
            case 5:
                return (<Agencies />)
            case 6:
                return (<Ads />)
            case 7:
                return (<Accountings />)
            default:
                return;
        }
    }

    render() {
        const { cookies } = this.state;
        const user = cookies.get('authorization');
        return (
            <div className='row'>
                <div className="menu_cont_wt">
                    <div className='col name_holder'>
                        <p className='mod_name'>{user.name}</p>
                        <p className='mod_desc'>Moderator</p>

                        <Menuitem logout={() => this.logout()} />
                    </div>
                </div>
                <div className='divider' />
                <div className="cont-cont-wt">
                    {this.showscreens()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { user: state.userInfoReducer }
}

export default connect(mapStateToProps)(Main_wt);
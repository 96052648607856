import React, { Component } from 'react';
import {Image,Loader,Dimmer,Icon} from 'semantic-ui-react';
import Img from '../models/image';

export class ImgUploadItem extends Component {

    
    state = {
        Image: this.props.image,
        status:'uploading',
        onOver: false,
        cancelled: false
    }

    handleState=(a)=>{
        this.setState({
            onOver:a
        })
    }

    componentDidMount() {
        
    }

    renderLoad() {
        const {onOver, Image, status} = this.state;

        if (onOver) {
            return (
                <Dimmer style={{position:'absolute'}} active>
                    <Icon name='delete' size="large" onClick={()=> {}} ></Icon>
                </Dimmer>
            );
        }
    }
    

    render() {
        const url = URL.createObjectURL(this.props.image);
        return (
            <div 
                onMouseLeave={()=>this.handleState(false)} 
                onMouseOver={()=>this.handleState(true)} 
                style={{position:'relative'}}>
                {this.renderLoad()}
                <Image src={url} size='small' />
            </div>
        )
    }
}

export default ImgUploadItem

import Config from '../config/config';


export default class Order {

    constructor(order) {

        this._id = order.id;
        this._date = order.date;
        this._addons = order.addons;
        this._total = order.total;
        this._invoice = order.invoice;
        this._item = order.item;
        this._name = order.name;
        this._phone = order.phone;
        this._status = order.status;
        this._user_id = order.user_id;
        this._agency_id = order.agency_id;
        this._agency_name = order.agency_name;
        this._beginning_date = order.beginning_date;
        this._end_date = order.end_date;
        this._trip = order.trip;
        this._trip_id = order.trip_id;
        this._payment = [];

        if(this._status > 0)
        {
            this.getPaymentInfo();
        }

    }

    async setOrderStatus(status) {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/orderstatus', {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                "id": this._id,
                "status": status,
                'user_id':this._user_id,
                'tour':this._trip,
                "trip_id":this._trip_id
            })
        });
        const responseJson = await response.json();
        if(responseJson.message.length>24) {
            window.location.reload();
        }
    }

    async verifyPayment() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/verify-payment', {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                "id": this._id,
                "user_id": this._user_id,
                'tour':this._trip,
                'trip_id':this._trip_id

            })
        });
        const responseJson = await response.json();
        if(responseJson.message="success") {
            window.location.reload();
        }
    }

    
    async recoverOrder() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/recover-order', {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                "id": this._id,
                'trip_id':this._trip_id
            })
        });
        const responseJson = await response.json();
        if(responseJson.message="success") {
            window.location.reload();
        }
    }
    

    async cancelOrder() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/order', {
            method: 'DELETE',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                "id": this._id,
                'trip_id':this._trip_id
            })
        });
        const responseJson = await response.json();
        if(responseJson.message="succes") {
            window.location.reload();
        }
    }
    
    
    async getPaymentInfo() {
        const payment = await fetch('https://weekendtrips.az:8443/api/mod_wt/getpaymentinfo', {
            method: 'POST',
            headers: {
                "Accept": 'application/json',
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                "order_id": this._id,
            })
        });

        const paymentJson = await payment.json();
        this._payment = paymentJson.results;
    }

  }
import * as actionTypes from '../actions/actionTypes'

const guest = {
    name : 'Guest'
}
const userInfoReducer = (state=guest,action) => {
    let newUserInfo;
    switch (action.type) {
        case actionTypes.ADD_USER_INFO:
            return newUserInfo = action.payload
    
        default:
            return state;
    }
}

export default userInfoReducer;
import React, { Component } from 'react';
import {Button,Table,Modal} from 'semantic-ui-react';
import Moment from 'moment';
import Common from '../config/config';

export class OrderTableRow extends Component {

    async toggleAccountings(id) {
        const results = await fetch('https://weekendtrips.az:8443/api/mod_wt/accountings',{
            method:'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Common,
            },
            body: JSON.stringify({
                id: id
            })
        });
        const resultJson = await results.json();
        if(resultJson.message === 'success'){
            window.location.reload()
        } else {
            alert('Xəta baş verdi. Az sonra yenidən cəhd edin.')
        }
    }

    render() {
        const {id, agency_id, agency, calculation_time, revenue, commission, isPaymentSent} = this.props.item;
        return (
            <Table.Row>
                <Table.Cell>{id}</Table.Cell>
                <Table.Cell>{agency} | ID: {agency_id}</Table.Cell>
                <Table.Cell>{revenue}</Table.Cell>
                <Table.Cell>{commission}</Table.Cell>
                <Table.Cell>{Moment(calculation_time).format('DD.MM.YYYY')}</Table.Cell>
                <Table.Cell>
                    <div className='row d-flex align-items-center'>
                        {
                            !isPaymentSent ? <Button onClick={()=> this.toggleAccountings(id)} icon='check' color='green' >Ödəndi olaraq işarələ</Button> : <p>{isPaymentSent ? ' Ödənilib' : ' Ödənməyib'}</p>
                        }
                    </div>
                </Table.Cell>
            </Table.Row>
        )
    }
}

export default OrderTableRow

import React, { Component } from 'react'
import { MdMap,MdShoppingCart,MdExitToApp, MdSmartphone, MdComment, MdBusinessCenter, MdAccountBalance} from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';

class Menuitem extends Component {
    
    go = (url) => {
        window.location.replace(url);
    }

    render() {
        return (
            <div className='d-flex flex-column'>
                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/orders')}>
                    <MdShoppingCart className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Sifarişlər</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/users')}>
                    <FaUsers className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>İstifadəçilər</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/tours')}>
                    <MdMap className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Turlar</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/messages')}>
                    <MdComment className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Mesajlar</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/ads')}>
                    <MdSmartphone className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>App elanları</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/agencies')}>
                    <MdBusinessCenter className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Agentliklər</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.go('/main_wt/accountings')}>
                    <MdAccountBalance className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Hesabatlar</p>
                </div>

                <div className='menu-item-wt row' onClick={ ()=> this.props.logout()}>
                    <MdExitToApp className='menu-item-wt-logo' />
                    <p className='menu-item-wt-title'>Çıxış</p>
                </div>
            </div>

        )
    }
}
export default Menuitem;

import React, { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import Common from '../config/common';
import Moment from 'moment';
import axios from 'axios';

const UsersersTableRow = ({ info }) => {

    const deleteUser = () => {
        // axios({
        //     url: `https://weekendtrips.az:8443/api/mod_wt/orders/${info.id}`,
        //     method: "DELETE",
        //   }).then(()=> console.log("deleted")).catch((err) => console.log(err))
    }

    return (
        <Table.Row style={{ backgroundColor: parseInt(info.id) % 2 ? '#F8F9F9' : '#fff' }}>
            <Table.Cell>{info.name}</Table.Cell>
            <Table.Cell>{info.id}</Table.Cell>
            <Table.Cell>{info.phone}</Table.Cell>
            <Table.Cell>{info.email}</Table.Cell>
            {/* <Table.Cell>
            <Button color='red' onClick={deleteUser} >Silmək</Button>
            </Table.Cell> */}
            {/* <Table.Cell style={{width:200}}>{info.fcmtoken}</Table.Cell> */}
           
        </Table.Row>
    )
}

export default UsersersTableRow
import React from 'react';
import Moment, { isDate } from 'moment';
import Tour from '../models/tour';
import _ from 'lodash';
import { Button,Header,Modal,Input, Form,TextArea, Segment,Checkbox, Icon, Popup} from 'semantic-ui-react';
import CustomCheckBox from './customCheckBox';
import Bus from './bus';
import PropTypes from 'prop-types';
var DAY = 1000 * 60 * 60 * 24;


class TourItem extends React.Component {
    static propTypes = {
        tour: PropTypes.object
    }


    constructor(props) {
        super(props);
        this.state = {
            open:false,
            open2:false,
            open3:false,
            open4:false,
            value:"bus",
            changed:[],
            bus_name:'',
            bus_count:0,
            bus_list:[],
            Tour: new Tour(this.props.tour),
            sellerPrivileges:{}
        }
    }

    onClose = (e) => {
        const {name} = e.target;
        this.setState({[name]: false});
    }

    componentDidMount() {
        const {Tour} = this.state;
        Tour.getBuses()
            .then(data => this.setState({bus_list: data}));

        Tour.getPrivileges()
            .then(data => this.setState({sellerPrivileges: data}));
    }

    handleChange = (e) => {
        const {name,value} = e.target;
        var {changed, Tour} = this.state;

        if(changed.includes(name)){
            Tour.setParams(name,value);
        } else {
            changed.push(name);
            this.setState({changed});
            Tour.setParams(name,value);
        }
        this.forceUpdate()
    }

    handlePriceChange = (e) => {
        const {name,value} = e.target;
        var {Tour} = this.state;
        Tour.setNewPrices(name,value);
        this.forceUpdate();
    }

    handleChecked = (event) => {
        const {value, checked} = event.target;
        var {changed, Tour} = this.state;
        var features = Tour._features;

        features.forEach(feature => {
           if (feature.value === value) {
            feature.isChecked = checked
           }
        });
        
        this.setState({ Tour },()=> {
            if(!changed.includes('features')){
                changed.push('features');
                this.setState({changed});
            }
        })        
    }

    handleCheckbox = (name) => {
        var {changed, Tour} = this.state;
        console.log(Tour['_'+name]);
        if(changed.includes(name)){
            Tour['_'+name] = Tour['_'+name] === '0' ? '1' : '0';
        } else {
            changed.push(name);
            this.setState({changed});
            Tour['_'+name] = Tour['_'+name] === '0' ? '1' : '0'; 
        }
        this.forceUpdate();
    }

    handleStateChanges = (e,{name,value}) => {
        this.setState({
            [name]:value
        });
    }

    renderHotTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="ticket icon orange"></i>
                S.Y
            </div>
        )
    }

    renderGuaranteedTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="check icon green"></i>
                G
            </div>
        )
    }

    renderArchievedTag(){
        return (
            <div className="ui label">
                <i aria-hidden="true" className="archive icon gray"></i>
                A
            </div>
        )
    }

    renderStatusToggler(active){
        if(active){
            return(
                <div className='blockButton' onClick={(event)=>{
                        this.changeTourStatus();
                        event.stopPropagation()}
                    }>
                    <Popup position='left center' content='Turu arxivlə' trigger={
                        <Button icon='archive' />
                    } /> 
                </div>
            )
        } else {
            return(
                <div className='blockButton' onClick={(event)=>{
                    this.changeTourStatus();
                    event.stopPropagation()}
                }>
                    <Popup position='left center' content='Turu aktiv et' trigger={
                        <Button icon='undo' color="green"/>
                    } /> 
                </div>
            )
        }
        
    } 

    renderBusToggler(count){
        if(parseInt(count) === 0){
            return(
                <div className='blockButton' onClick={(event)=>{
                        event.stopPropagation()}
                    }>
                    <Popup position='left center' content='Avtobusların aktivləşdirilməsi' trigger={
                        <Button onClick={(e)=>{e.preventDefault(); this.setState({open2:true})}} icon='bus'/>
                    } /> 
                </div>
            )
        } else {
            return(
                <div className='blockButton' onClick={(event)=>{
                    event.stopPropagation()}
                }>
                    <Popup position='left center' content='Aktivləşdirilmiş avtobuslara bax' trigger={
                        <Button onClick={(e)=>{e.preventDefault(); this.setState({open2:true})}} icon='bus'  color='green' />
                    } /> 
                </div>
            )
        }
    } 

    renderVerificationToggler(verified){
        if(verified){
            return(
                <div className='blockButton' onClick={(event)=>{ event.stopPropagation()} }>
                    <Popup position='left center' content='Tur təsdiqini ləğv et, sistemdə görünməsin.' trigger={
                            <Button onClick={(e)=>{e.preventDefault(); this.toggleVerify()}} icon='check' color='green'/>
                    } /> 
                </div>
            )
        } else {
            return(
                <div className='blockButton' onClick={(event)=>{ event.stopPropagation()} }>
                    <Popup position='left center' content='Turu təsdiqlə' trigger={
                        <Button onClick={(e)=>{e.preventDefault(); this.toggleVerify()}} icon='check'  color='gray' />
                    } /> 
                </div>
            )
        }
    } 

    renderCheckBoxes = () => {
        return _.map(this.state.Tour._features,(feature) => {
            return (<CustomCheckBox key={feature.id} handleCheckChieldElement={this.handleChecked} {...feature} />)
        });
    }

    changeTourStatus() {
        this.state.Tour.changeTourStatus().then(active =>
            this.setState({ active })
        )        
    }

    activateBus=()=> {
        const {Tour, bus_name} = this.state;
        if (bus_name.length > 4)
        {
            Tour.newBus(bus_name);
            this.setState({
                bus_name:'',
                bus_count:0
            })
        }
    }

    renderBuslist = () => {
        const { bus_list } = this.state;

        if(bus_list.length > 0) {
            return _.map(bus_list,(item) => {
                return (
                    <Bus key={item.id} bus={item} ></Bus>
                )
            })
        }
    }

    sendNewPrices() {
        const {Tour} = this.state;
        Tour.sendNewPrices();
    } 
    
    setPrivilege(agency, isDeleting) {
        this.state.Tour.setPrivilege(agency, isDeleting);
    }
    
    toggleVerify(){
        const {Tour} = this.state;
        Tour.changeVerificationStatus().then(verified => {
            window.location.reload()
        }) 
    }

    removeTour() {
        this.state.Tour.removeTour();
    }

    renderRemoveModal() {
        return(
            <Modal open={this.state.open3} basic size='small'>
                    <Header icon='trash' content='Turun silinməsi' />
                    <Modal.Content>
                    <p>
                        Turu silmək istədiyinizə əminsinizmi?
                        Silinən turların bərpası mümkün deyil.
                    </p>
                    </Modal.Content>
                    <Modal.Actions>
                    <Button basic color='red' onClick={()=> this.setState({open3: false})} inverted>
                        <Icon name='remove' /> Xeyr
                    </Button>
                    <Button color='red' onClick={()=> this.removeTour()} inverted>
                        <Icon name='checkmark' /> Bəli
                    </Button>
                    </Modal.Actions>
                </Modal>
        )
    }

    renderNewBusActivation() {
        const {Tour,changed, open2, bus_name, bus_count} = this.state;
        const { _name, _buscount } = Tour;
        return(
            <Modal size='small' dimmer='inverted' open={open2} onClose={()=>this.setState({open2:false})} centered>
                    <Modal.Header style={{backgroundColor:'#21ba45'}} ><p className='lead text-white'>{_name} üçün avtobusda yer seçimi</p></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>
                                {parseInt(_buscount) === 0? 'Bu tur üçün avtobusda yer seçimi aktivləşdirilməyib.':'Bu tur üçün artıq '+_buscount+' avtobus aktivləşdirilib.'}
                            </Header>

                            <Segment>
                                <label className='lead' ><b>Yeni avtobus aktiv etmək üçün: </b></label>
                                <div className='d-flex flex-column'>
                                    <Input name='bus_name' value={bus_name} onChange={this.handleStateChanges} placeholder='Avtobus adı' type='text' list='bus_names'></Input>
                                    <datalist id='bus_names' >
                                        <option value='isuzu' />
                                        <option value='kobra' />
                                        <option value='mb403' />
                                        <option value='neoplan' />
                                        <option value='otakar' />
                                        <option value='setra' />
                                        <option value='sprinter20' />
                                        <option value='travego' />
                                        <option value='vito7' />
                                        <option value='vito8' />
                                    </datalist>
                                    <Button color='green' onClick={this.activateBus} >Aktiv et</Button>
                                </div>
                            </Segment>

                            { parseInt(this.props.tour.buscount) > 0 ?
                                <Segment>
                                    <label className='lead' ><b>Mövcud avtobuslar: </b></label>
                                    <div className='d-flex flex-column' >
                                        {this.renderBuslist()}
                                    </div>
                                </Segment>
                             :null}
    
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                    </Modal.Actions>
                </Modal>
        )
    }

    renderEditingsModal() {
        const {Tour, changed} = this.state;
        const {adult_price, child_price, sng_price, dbl_price, exb_price} = Tour._prices;
        const {_hot, _name, _edate, _bdate, _qt, _desc_text, _ref_policy } = Tour;
        const diff = Moment(_bdate).diff(_edate) / DAY
        return(
            <Modal name='open' size='small' dimmer='inverted' open={this.state.open} onClose={()=>this.setState({open:false})} centered>
                    <Modal.Header style={{backgroundColor:'#21ba45'}} ><p className='lead text-white'>{_name}</p></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Header>
                                <Form.Field name='name' control={Input} type='text' value={_name} placeholder='Tur adı' onChange={this.handleChange} />
                            </Header>
                            <Form>
                                <Segment>
                                    <Form.Group>
                                      
                                        <React.Fragment>
                                        <Form.Field width={3} name='adult_price' type='number' control={Input} value={adult_price} label='Yetkinlər' onChange={this.handlePriceChange}/>
                                        <Form.Field width={3} name='child_price' type='number' control={Input} value={child_price} label='Azyaşlılar' onChange={this.handlePriceChange} />
                                        </React.Fragment>
                                        
                                        <React.Fragment>
                                        <Form.Field width={3} name='sng_price' type='number' control={Input} value={sng_price} label='Single' onChange={this.handlePriceChange} />
                                        <Form.Field width={3} name='dbl_price' type='number' control={Input} value={dbl_price} label='Double' onChange={this.handlePriceChange} />
                                        <Form.Field width={3} name='exb_price' type='number' control={Input} value={exb_price} label='Ex-Bed' onChange={this.handlePriceChange} />
                                        </React.Fragment>
                                        <Button icon positive onClick={()=>this.sendNewPrices()} >
                                            <Icon color='#fff' name='check' />
                                        </Button>
                                    </Form.Group>
                                </Segment>
                                <Form.Group widths='equal'>
                                    <Form.Field name='qt' type='number' control={Input} value={_qt} label='Boş yer' placeholder='yer' onChange={this.handleChange} />
                                    <Form.Field name='bdate' control={Input} label={`Başlayır ${_bdate}`} type='date' onChange={this.handleChange}/>
                                    <Form.Field name='edate' control={Input} label={`Bitir ${_edate}`} type='date' onChange={this.handleChange} />
                                </Form.Group>
                                <Segment className='d-flex flex-row justify-content-around' >
                                    <Form.Field control={Checkbox} checked={parseInt(Tour._guaranteed)} label='Qarantili' onChange={()=>this.handleCheckbox('guaranteed')}/>
                                    <Form.Field control={Checkbox} checked={parseInt(_hot)} label='Son yerlər' onChange={()=>this.handleCheckbox('hot')}/>
                                </Segment>
                                <Form.Field name='desc_text' control={TextArea} value={_desc_text} label='Tur mətni' placeholder='Tur haqqında...' onChange={this.handleChange} />
                                <Form.Field name='ref_policy' control={TextArea} value={_ref_policy} label='Qaytarılma şərtləri' placeholder='Qaytarılma şərtləri' onChange={this.handleChange} />
                                <label>Tur xüsusiyyətləri</label>
                                <div className='col black-text'>
                                    <label><b>Tur xüsusiyyətləri</b></label>
                                    <ul>
                                        {this.renderCheckBoxes()}
                                    </ul>
                                </div> 

                            </Form>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>Tour.setNewParams(changed)} inverted color="green">Yenilə</Button>
                    </Modal.Actions>
                </Modal>
        )
    }

    renderSellerPrivileges() {
        const {open4, sellerPrivileges} = this.state;
        return(
            <Modal size='small' dimmer='inverted' open={open4} onClose={()=>this.setState({open4:false})} centered>
                <Modal.Header style={{backgroundColor:'#1678C2'}} ><p className='lead text-white'>Agentliklərə satış səlahiyyətinin verilməsi</p></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>Səlahiyyət vermək istədiyiniz agentlikləri seçin.</Header>
                        
                        {
                            _.map(sellerPrivileges,item => {
                                const privileged = item.existance
                                return(
                                    <Segment className='d-flex flex-column'>
                                        <img className='agency-logo-min' src={'https://weekendtrips.az/app_data/agency_avatar/'+item.profile_photo} alt="thumb"/>
                                        <label className='lead' ><b>{item.name}</b></label>
                                        <p>{!item.existance ? 'Satış səlahiyyəti yoxdur' : 'Satış səlahiyyəti var'}</p>
                                        {<Button content={privileged ? 'Səlahiyyəti sil' : 'Səlahiyyət ver'} primary={!privileged} onClick={() => this.setPrivilege(item.id, privileged)} /> }
                                    </Segment>
                                )
                            })
                        }

                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                </Modal.Actions>
            </Modal>
        )
    }

    render() {
        const { Tour } = this.state;
        const {adult_price, child_price, sng_price, dbl_price, exb_price} = Tour._prices;
        const {
            _hot,
            _active,
            _guaranteed,
            _name,
            _edate,
            _bdate,
            _qt,
            _buscount,
            _thumb,
            _verified,
            _sellerId,
            _agency_name
        } = Tour;

        return (
            <div className='itemContainer'>
                
                <div style={{display:"flex",flexDirection:'column',width:'7%'}}>

                    {_sellerId == '10000' ? <Button size='tiny' icon='home' color='orange'/> : null}
                    { _hot ? this.renderHotTag() : null}       
                    { _active ? null : this.renderArchievedTag()}       
                    { _guaranteed ? this.renderGuaranteedTag() : null} 
                
                </div>     
                <img  alt="thumb" src={`https://weekendtrips.az/app_data/tour_img/${_thumb}`}/> 
                <div className='col cred_container'>
                    <p className='lead'>{_name}</p>
                    <p className='shorttexts'>{_agency_name}</p>
                    <p className='shorttexts'>Baş tutuma tarixləri: {_bdate} - {_edate}</p>
                    <p className='shorttexts'>Qiymətlər: Yetkin: {adult_price} AZN, Azyaşlı: {child_price} AZN, SNG: {sng_price}, DBL: {dbl_price}, EXB: {exb_price}</p>
                    <p className='shorttexts'>Boş yer: {_qt}</p>
                </div>

                <div className='optionsBlock'>
                    <div className='blockButton' onClick={(event)=>{ event.stopPropagation() }}>
                        <Popup position='left center' content='Turu sil' trigger={
                            <Button onClick={(e)=>{e.preventDefault(); this.setState({open3:true})}} icon='remove' />
                        } />
                    </div>
                    <div className='blockButton' onClick={(event)=>{ event.stopPropagation() }}>
                        <Popup position='left center' content='Satış səlahiyyətlərinə bax' trigger={
                            <Button onClick={(e)=>{e.preventDefault(); this.setState({open4:true})}} icon='cart' />
                        } />
                        
                    </div>
                </div>

                <div className='optionsBlock'>
                    {this.renderStatusToggler(_active)}
                    {this.renderBusToggler(_buscount)}
                    {this.renderVerificationToggler(_verified)}
                    <div className='blockButton' onClick={(event)=>{ event.stopPropagation() }}>
                        <Popup position='left center' content='Tur məlumatlarını dəyişdir' trigger={
                            <Button onClick={(e)=>{e.preventDefault(); this.setState({open:true})}} icon='edit' />
                        } />
                    </div>
                </div>

                {this.renderEditingsModal()}
                {this.renderNewBusActivation()}
                {this.renderRemoveModal()}
                {this.renderSellerPrivileges()}
            
            </div>
        )
    }
}



export default TourItem;

import Config from '../config/config';
import Moment from 'moment';

export default class Tour {

    constructor(tour) {
        this._id = tour.id;
        this._name = tour.name;
        this._hot = tour.hot;
        this._sellerId = tour.sellerId;
        this._thumb = tour.thumbnail;
        this._agency_name = tour.agency_name;
        this._active = tour.active || 0;
        this._guaranteed = tour.guaranteed;
        this._edate = tour.edate;
        this._bdate = tour.bdate;
        this._multiday = Moment(tour.edate).diff(tour.bdate) / 86400000 > 0
        this._qt = tour.qt;
        this._buscount = tour.buscount;
        this._verified = tour.verified;
        this._desc_text = tour.desc_text;
        this._ref_policy = tour.ref_policy;
        this._prices = {
            adult_price: tour.adult_price,
            child_price: tour.child_price,
            sng_price: tour.sng_price,
            dbl_price: tour.dbl_price,
            exb_price: tour.exb_price
        }
        this._features = [
            {id:'1',value : 'bus',isChecked:false, label:'Avtobus nəqliyyatı qiymətə daxildir'},
            {id:'2',value : 'trn',isChecked:false, label:'Dəmiryolu nəqliyyatı qiymətə daxildir'},
            {id:'3',value : 'air',isChecked:false, label:'Hava nəqliyyatı qiymətə daxildir'},
            {id:'4',value : 'sea',isChecked:false, label:'Gəmi ilə gəzinti'},
            {id:'5',value : 'bb',isChecked:false, label:'Ancaq səhər yeməyi qiymətə daxildir'},
            {id:'6',value : 'fb',isChecked:false, label:'Səhər, nahar, şam yeməkləri qiymətə daxildir.'},
            {id:'7',value : 'cas',isChecked:false, label:'Çay süfrəsi qiymətə daxildir'},
            {id:'8',value : 'hot',isChecked:false, label:'Oteldə gecələmə qiymətə daxildir'},
            {id:'9',value : 'eks',isChecked:false, label:'Ekskursiyalar'},
            {id:'10',value : 'fot',isChecked:false, label:'Foto çəkiliş qiymətə daxildir'},
            {id:'11',value : 'knt',isChecked:false, label:'Kanat'},
            {id:'12',value : 'ybh',isChecked:false, label:'Yol boyu əyləncəli və hədiyyəli oyunlar'},
            {id:'13',value : 'bln',isChecked:false, label:'Balon turu'},
            {id:'14',value : 'atv',isChecked:false, label:'4 təkərli motoikletlə gəzinti'},
            {id:'15',value : 'atc',isChecked:false, label:'Atçılıq'},
            {id:'16',value : 'ati',isChecked:false, label:'Atıcılıq'},
            {id:'17',value : 'raf',isChecked:false, label:'Raftinq'},
            {id:'18',value : 'par',isChecked:false, label:'Paraplan'},
            {id:'19',value : 'ovc',isChecked:false, label:'Ovçuluq'},
            {id:'20',value : 'lnc',isChecked:false, label:'Nahar yeməyi'},
            {id:'23',value : 'spr',isChecked:false, label:'Şam yeməyi'},
            {id:'21',value : 'cou',isChecked:false, label:'Kombi tur (Iki və ya daha artıq ölkə)'},
            {id:'22',value : 'cin',isChecked:false, label:'Ölkə daxili tur'}
        ];
        this._NewTourFeatures = tour.features ? tour.features : '';

        if(tour.vals) {
            this.fetchFeatures(tour.vals);
        }
    }

    fetchFeatures(vals) {
        var featuresArray = vals.split(',');
        var i = 0;
        while (i<Object.keys(this._features).length) {
            if(featuresArray.includes(this._features[i].value)){
                this._features[i].isChecked = true;
                i++;
            } else {
                i++;
            }
        }
    }

    formatFeatures() {
        var newArray = [];
        this._features.forEach(feature => {
            if(feature.isChecked){
                newArray.push(feature.value)
            }
        });
        return(newArray);
    }

    async getBuses() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/getbus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
            }),
        });
        const resJson = await response.json();
        return resJson.results;
    }

    async setPrivilege(agency, isDeleting) {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/privileges', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                trip: this._id,
                agency,
                isDeleting
            }),
        });
        const resJson = await response.json();
        const {success, message} = resJson;
        
        if(success){
            alert(message)
            window.location.reload()
        } else {
            alert('Xəta baş verdi.')
        }
    }

    async getPrivileges() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/privileges/'+this._id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            }
        });
        const resJson = await response.json();
        return resJson.results;
    }


    async newBus(bus_name) {
        var i = 0;
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/newbus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                bus_name,
                bus_count: this._buscount
            }),
        });
        const responseJson = await response.json();
        if(responseJson.message === 'success'){
            this._buscount = parseInt(this._buscount) + 1;
            console.log('success bc ' + this._buscount);
            alert(responseJson.message);
            window.location.reload();
            i++;
        } else {
            alert(responseJson.message);
        }
    }

    async changeTourStatus() { 
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/tourstatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                status: parseInt(this._active) === 1 ? '0' : '1',
            }),
        });
        const responseJson = await response.json();
        if(responseJson.message.length>24) {
            this._active = !this._active;
        }
        else {
            alert(responseJson.message);
        }
        return this._active;
    }

    async removeTour() {
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/tour', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id
            })
        });
        const responseJson = await response.json();
        if (responseJson.success) {
            window.location.reload();
        } else {
            alert('Tur silinərkən xəta baş verdi. Bir az sonra səhifəni yeniləyərək yenidən cəhd edə bilərsiniz.')
        }
    }

    async changeVerificationStatus() { 
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/toggle-verify/tour', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                verified: parseInt(this._verified) === 1 ? '0' : '1',
            }),
        });
        const responseJson = await response.json();
        const {success} = responseJson
        if(success) {
            this._verified = !this._verified;
        } else {
            alert(responseJson.message);
        }
        return this._verified;
    }

    async publishTour(images) { 
        const data = new FormData();
        const {_name,_prices,_qt,_NewTourFeatures,_edate,_bdate,_desc_text,_guaranteed, _ref_policy} = this;
        var trip = {
            name: _name,
            qt: _qt,
            features: _NewTourFeatures,
            edate: _edate,
            bdate: _bdate,
            desc_text: _desc_text,
            guaranteed: _guaranteed,
            ref_policy: _ref_policy,
            prices: _prices
        };

        for (let i = 0 ; i < images.length ; i++) {
            data.append("file", images[i]);
        }
        data.append('tour', JSON.stringify(trip))
        data.append('prices', JSON.stringify(_prices))
        
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/tour', {
            method: 'POST',
            headers: {
                'Authorization':  'Bearer ' +Config,
            },
            body: data,
        })
        try {
            const responseJson = await response.json();
            return responseJson.success;
        }
        catch (error) {
            
        }
       
    }

    returnFeatures = (obj) => {
        var i = 0;
        var tmpArr = [];

        while(i<obj.length) {
            if(obj[i].isChecked){
                tmpArr.push(obj[i].value); 
            }
            i++;
        }
        return tmpArr.toString();
    }

    async setNewParams(changes){
        var i = 0;
        var tourEditings = {};
        while (i<changes.length) {
            tourEditings[changes[i]] = this['_'+changes[i]];
            i++;
        }
        if(changes.includes('features')){
            tourEditings['features'] = this.returnFeatures(this._features);
        }
        this.sendNewParams(tourEditings);
    }

    setParams(key,value) {
        this['_'+key] = value;
    }

    setNewPrices(name,value){
        this._prices[name] = value
    }

    setBoolParams(key) {
        this['_'+key] = this['_'+key] === '0' ? '1' : '0'; 
    }

    async sendNewParams (tourEditings){
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/tour', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                tourEditings
            }),
        });

        const responseJson = await response.json();
        if (responseJson.message === 'success') {
            window.location.reload();
            alert(responseJson.message)
        } else {
            alert(responseJson.message)
        }
    }

    async sendNewPrices(){
        const response = await fetch('https://weekendtrips.az:8443/api/mod_wt/tour', {
            method: 'PATCH', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization':  'Bearer ' +Config,
            },
            body: JSON.stringify({
                id: this._id,
                mode: 'price',
                prices: this._prices
            })
        });
        const responseJson = await response.json();
        if (responseJson.message === 'success') {
            window.location.reload();
            alert(responseJson.message)
        } else {
            alert(responseJson.message)
        }
    }
    
}